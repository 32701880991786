export const translateBr = {
  DOC_URL_ACTIONS: 'https://documentacao.senior.com.br/seniorxplatform/manual-do-usuario/ronda/#security-hub/monitoramento.htm',
  DOC_URL_MONITORING: 'https://documentacao.senior.com.br/seniorxplatform/manual-do-usuario/ronda/#security-hub/monitoramento.htm',
  DOC_URL_VIRTUAL_LOBBY: 'https://documentacao.senior.com.br/seniorxplatform/manual-do-usuario/ronda/#security-hub/monitoramento.htm',
  PHYSICAL_LOCATIONS: 'Locais físicos',
  OPERATION: 'Operação',
  FLOOR_PLAN: 'Planta baixa',
  CAMERAS: 'Câmeras',
  INFORMATION_RELOAD_IN: 'As informações serão atualizadas em',
  RELOAD_NOW: 'Recarregar agora',
  PEOPLE_PRESENT: 'Pessoas presentes',
  IRREGULAR_PRESENCES: 'Presenças irregulares',
  IRREGULAR_PRESENCE_BY_WORK_SCHEDULE: 'Pessoas com presença irregular (motivo: escala)',
  NO_IRREGULAR_PRESENCE_AT_THE_LOCATION: 'Não há presença irregular nesse local',
  WEB_MONITORING: 'Monitoramento Web',
  DATE_OF_ACCESS: 'Data de acesso',
  PHONE_NUMBER: 'Telefone',
  LOCATION: 'Local',
  DOES_NOT_HAVE_ANY_CHILD_PHYSICAL_LOCATIONS: '{{physicalLocation}} não possui nenhum local filho.',
  CHILD_PHYSICAL_LOCATIONS: 'Locais filhos',
  OCCURRENCES: 'Ocorrências',
  PRIORITY_PLANNED: 'Planejada',
  PRIORITY_LOW: 'Prioridade baixa',
  PRIORITY_MEDIUM: 'Prioridade média',
  PRIORITY_HIGH: 'Prioridade alta',
  PRIORITY_CRITICAL: 'Crítica',
  PENDING: 'Pendente',
  IN_PROGRESS: 'Em progresso',
  STOPPED: 'Em pausa',
  DONE: 'Concluído',
  NO_PERSON_PRESENT_AT_THE_LOCATION: 'Não há pessoas presentes neste local',
  LAST_ACCESSES: 'Últimos acessos',
  DOES_NOT_HAVE_ANY_ACCESS: '{{physicalLocation}} não possui nenhum acesso.',
  UNKNOWN_ACCESS_VALID: 'Acesso permitido',
  UNKNOWN_ACCESS_DENIED: 'Acesso negado',
  ENTRANCE_ACCESS_VALID: 'Entrada permitida',
  ENTRANCE_ACCESS_DENIED: 'Entrada negada',
  EXIT_ACCESS_VALID: 'Saída permitida',
  EXIT_ACCESS_DENIED: 'Saída negada',
  DEVICES: 'Dispositivos',
  DOES_NOT_HAVE_ANY_OCCURRENCES: '{{physicalLocation}} não possui ocorrências registrados.',
  DOES_NOT_HAVE_ANY_DEVICE: '{{physicalLocation}} não possui dispositivos.',
  EVENTS: 'Eventos',
  DEVICE_STARTED: 'Dispositivo inicializado',
  DEVICE_ONLINE: 'Dispositivo online',
  DEVICE_OFFLINE: 'Dispositivo offline',
  OFFLINE_MODULE: 'Módulo offline',
  ISSUANCE_OF_TIME_AND_ATTENDANCE_REPORT: 'Relatório de marcação',
  STARTED_USING_USB_FISCAL_PORT: 'Usando porta fiscal',
  PAPER_REEL_REPLACED: 'Bobina de papel trocada',
  LACK_OF_PAPER: 'Sem papel',
  NO_PAPER: 'Sem papel',
  LOW_MEMORY: 'Pouca memória',
  NO_MEMORY: 'Memória',
  BATTERY_REPLACED: 'Bateria trocada',
  STARTED_USING_BATTERY: 'Utilizando bateria',
  STARTED_USING_ENERGY: 'Utilizando energia',
  DEVICE_LOCKED: 'Dispositivo bloqueado',
  DEVICE_UNLOCKED: 'Dispositivo desbloqueado',
  TAMPER: 'Dispositivo adulterado (Tamper)',
  DOOR_HELD_OPEN: 'Porta aberta',
  DOOR_FORCED_OPEN: 'Intrusão',
  MEMORY: 'Memória',
  BATTERY: 'Bateria',
  PAPER: 'Papel',
  ALARMED: 'Alarmado',
  DEFAULT_STATE: 'Estado padrão',
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  DOES_NOT_HAVE_ANY_EVENTS: '{{physicalLocation}} não possui eventos registrados.',
  UNLOCKED: 'Desbloqueado',
  LOCKED: 'Bloqueado',
  POWER: 'Rede',
  HAS_PAPER: 'Tem papel',
  AVALIABLE_MEMORY: 'Disponível',
  EXIT: 'Saída',
  ENTRANCE: 'Entrada',
  UNKNOWN: 'Desconhecida',
  ACCESS_VALID: 'Acesso permitido',
  ACCESS_DENIED_PERMISSION: 'Local não permitido',
  ACCESS_DENIED_SITUATION: 'Negado por situação',
  ACCESS_DENIED_CARD_VALIDITY: 'Validade da credencial',
  ACCESS_DENIED_LEVEL_CONTROLER: 'Controle de nível',
  ACCESS_DENIED_CREDIT_ACCESS: 'Pela falta de crédito de acesso',
  ACCESS_DENIED_PERSON_RANGE: 'Pela faixa horária da pessoa',
  ACCESS_DENIED_LOCAL_RANGE: 'Pela faixa horária do local',
  ACCESS_DENIED_CARD_NOT_FOUND: 'Cartão não encontrado',
  ACCESS_VALID_ACCOMPANY: 'Acompanhado pelo autorizador',
  ACCESS_DENIED_ACCOMPANY: 'Acompanhado pelo autorizador',
  ACCESS_DENIED_INVALID_AUTHORIZER: 'Autorizador incorreto',
  ACCESS_DENIED_WAITING_FOR_NEXT_VALIDATION: 'Aguardando pela próxima validação',
  ACCESS_DENIED_ANTI_PASSBACK: 'Bloqueado por anti-dupla',
  ACCESS_DENIED_CREDIT_RANGE: 'Fora da faixa de crédito de acesso',
  ACCESS_DENIED_STOCKING_CONTROL: 'Lotação atingida',
  ACCESS_DENIED_NOT_PARKING_SPACE_TYPE: 'Tipo de vaga indisponível',
  ACCESS_DENIED_NOT_PARKING_SPACE: 'Não há vaga',
  ACCESS_COERCION: 'Acesso sob coação',
  ACCESS_DENIED_BIOMETRY: 'Por biometria',
  ACCESS_DENIED_CARD_FORMAT_ERROR: 'Formato de cartão inválido',
  ACCESS_DENIED_FACILITY_CODE: 'Pelo facility code',
  ACCESS_DENIED_PASSWORD: 'Pela senha',
  ACCESS_DENIED_SECOND_CARD_NOT_PRESENTED: 'Segundo cartão não apresentado',
  ACCESS_DESISTENCE: 'Desistência de acesso',
  ACCESS_VALID_AUTHORIZER: 'Pelo autorizador',
  ACCESS_VALID_FACILITY_CODE: 'Pelo facility code',
  ACCESS_VALID_FRAUD: 'Burla',
  ACCESS_VALID_OUT_REPOSE: 'Fora do repouso',
  ACCESS_DENIED: 'Acesso negado',
  ACCESS_DENIED_CREDENTIAL_NOT_FOUND: 'Por credencial não encontrada',
  ACCESS_DENIED_CREDENTIAL_VALIDITY: 'Por validade de credencial',
  MONITOR: 'Monitor',
  PERSON: 'Pessoa',
  DIRECTION: 'Direção',
  PHYSICAL_LOCATION: 'Local físico',
  DEVICE: 'Dispositivo',
  ACCESS_TYPE: 'Tipo do acesso',
  ACCESS_MONITORING: 'Monitoramento de acesso',
  ACCESS_DATE_TIME: 'Data/hora do acesso',
  NAME: 'Nome',
  DEVICE_STATUS: 'Status do dispositivo',
  ADDRESS: 'Endereço',
  PAPER_STATUS: 'Status do papel',
  MEMORY_STATUS: 'Status da memória',
  POWER_SUPPLY: 'Fonte de energia',
  SITUATION: 'Situação',
  NO_REGISTERED_DEVICES: 'Não existem dispositivos cadastrados',
  DEVICE_SEARCH: 'Consulta de dispositivos',
  NEW_CREDENTIAL: 'Nova credencial',
  REGISTER_ENTRANCE: 'Registro de entrada',
  REGISTER_CHECKOUT: 'Registro de saída',
  MOVEMENTS: 'Movimentação',
  DETAILS: 'Detalhes',
  OPEN_ENTRANCES: 'Entradas em aberto',
  ASSOCIATED_PEOPLE: 'Pessoas associadas',
  INFORMATION: 'Informações',
  ACTIONS: 'Ações',
  IDENTIFICATION: 'Identificação',
  PERSON_DETAILS: 'Detalhes da pessoa',
  REGISTER_ENTRY: 'Registro de entrada',
  SELECT_A_DOCUMENT_TYPE: '< Selecione um tipo',
  NEXT_STEP: 'Próximo',
  TYPE_TO_SEARCH: 'Escreva para pesquisar',
  DOCUMENT: 'Documento',
  EMAIL_ADDRESS: 'Endereço de e-mail',
  BACK: 'Voltar',
  ROLE: 'Papel',
  EXPIRATION_DATE: 'Data de validade',
  VISITED_PERSON: 'Pessoa visitada',
  SAVE: 'Salvar',
  SEARCH_VISITED_PERSON: 'Pesquisa pessoa visitada',
  DOCUMENT_TYPE: 'Tipo de documento',
  MANDATORY_FIELD: 'Campo obrigatório',
  SAVED_SUCCESSFULLY: 'Salvo com sucesso',
  CHECKOUT: 'Registro de saída',
  SELECT_PERSON: 'Selecione uma pessoa',
  REGISTER_LEAVE: 'Registrar saída',
  CANCEL: 'Cancelar',
  NAME_OR_DOCUMENT: 'Nome ou documento',
  THE_ADDRESS_MIGHT_BE_INCORRECT_NOT_AVAILABLE_ANYMORE_THE_CONTENT_MIGHT_HAVE_BEEN_REMOVED: 'O endereço pode estar incorreto, não estar mais disponível ou o conteúdo pode ter sido removido',
  PAGE_NOT_FOUND: 'Página não encontrada',
  PAGE_ERROR_ACCESS_DENIED: 'Acesso negado',
  YOU_NOT_HAVE_PERMISSIONS_TO_ACCESS_THIS_CONTENT: 'Você não possui permissão para acessar esse conteúdo',
  OOPS: 'Ops!',
  AN_UNEXPECTED_ERROR_OCCURRED_ON_THE_SERVER: 'Ocorreu um erro inesperado no servidor',
  THIS_MIGHT_HAVE_OCCURRED_DUE_TO_MAINTENANCE_ON_OUR_SERVERS: 'Isso pode ter ocorrido devido a alguma manutenção em nossos servidores ou alguma falha durante a navegação.',
  NORMAL: 'Normal',
  SELECT_CAMERA: 'Selecione a câmera',
  CAMERA_NOT_FOUND: 'Câmera não encontrada',
  ERROR_EXECUTING_LIVE_STREAMING: 'Não foi possível obter a imagem do servidor',
  NO_CCTV_CAMERAS: 'Não é possível buscar histórico de imagens de câmeras que não possuem servidor',
  HIDE_CAMERA: 'Ocultar câmera',
  UNAVAIBLE_SERVICE: 'Serviço indisponível',
  PROXY_SERVER_NOT_FOUND: 'Servidor proxy não encontrado',
  NO_CAMERAS_ASSOCIATED_TO_LOCATION: 'Não há câmeras associadas a este local',
  SELECTED_FILTERS: 'Filtros selecionados',
  ALL: 'Todos',
  GROUP: 'Grupo',
  FILTERS: 'Filtros',
  NO_ACCESS_REGISTERED: 'Nenhum acesso registrado',
  CLEAR: 'Limpar',
  SEARCH: 'Pesquisar',
  SELECT_FILTERS: 'Selecionar filtros',
  CLOSE_NOTIFICATIONS: 'Fechar notificações',
  VIEW_MORE: 'Ver mais',
  NO_NOTIFICATIONS: 'Não há notificações',
  NO_AVAILABLE_ACTIVE_LOBBY: 'Não foi encontrada nenhuma portaria ativa disponível para seu usuário',
  CONTACT_SYSTEM_ADMINISTRATOR: 'Em caso de dúvidas entre em contato com o administrador de seu sistema',
  WEB_MONITOR: 'Monitor Web',
  VIRTUAL_LOBBY: 'Portaria Virtual',
  HELP: 'Ajuda',
  NOTIFICATIONS: 'Notificações',
  LOGOFF: 'Sair',
  NO_INFORMATION_LOBBY: 'Não há informações adicionais disponíveis para esta portaria',
  NO_ACTIONS_REGISTERED: 'Não há ações cadastradas',
  FIND_MORE_HERE: 'Saiba mais aqui',
  THIS_VIRTUAL_LOBBY_HAS_NO_OPEN_ENTRIES: 'Essa portaria não possui entradas em aberto',
  REGISTERED_LEAVE_SUCCESSFUL_FOR_PERSON: 'Registro de saída feito com sucesso para {person}',
  THERE_ARE_NO_MEMBERS_ASSOCIATED_WITH_THIS_ENTRY: 'Essa portaria não possui pessoas associadas',
  OPEN_ENTRIES: 'Entradas em aberto',
  ERROR_VALIDATING_USER: 'Não foi possível obter a imagem do servidor',
  ADD_CAMERA_TO_CONTAINER: 'Adicione uma câmera',
  ACTIVATION_EXECUTED_SUCCESSFULLY: 'Acionamento realizado com sucesso',
  ROLE_FOR_PERSON_ROLE_OVERLAP: 'A pessoa já possui este papel',
  SAVE_FAILED: 'Não foi possível salvar',
  CREDENTIAL_NUMBER: 'Número da credencial',
  IDENTITY_DOCUMENT: 'Documento',
  SELECT_CREDENTIAL_TYPE: '< Selecione um tipo',
  ISSUE_CREDENTIAL: 'Entregar credencial',
  ISSUE_CREDENTIAL_BUTTON: 'Entregar',
  PERSON_ROLE_NOT_ACTIVE_PERSON: 'Não é possivel conceder acesso a essa pessoa bloqueada, para mais informações contate um administrador.',
  EXPIRATION_DATE_CANNOT_BE_LATER_THAN_CURRENT_DATE: 'Data de validade deve ser maior que a data atual',
  CANNOT_GRANT_ACCESS_TO_BLOCKED_PERSON_FOR_MORE_INFORMATION_CONTACT_YOUR_ADMINISTRATOR: 'Não é possivel conceder acesso a essa pessoa bloqueada, para mais informações contate um administrador.',
  CANNOT_GRANT_ACCESS_TO_BLOCKED_PERSON_FOR_MORE_INFORMATION_ACCESS_THE_PERSON_REGISTRATION: 'Não é possivel conceder acesso a essa pessoa bloqueada, para mais informações acesse o cadastro da pessoa.',
  ACCESS: 'Acessar',
  CREDENTIAL_EMITTED_SUCCESSFUL_FOR_PERSON: 'Credencial emitida com sucesso para {person}',
  CREDENTIAL_NUMBER_ALREADY_IN_USE: 'Esse número de crachá já está sendo utilizado por outra pessoa',
  VISITED_AND_VISITOR_SAME_PERSON: 'Visitante e visitado não podem ser a mesma pessoa',
  CREDENTIAL_LIMIT_EXCEEDED: 'Excedeu o limite disponível de credenciais ativas no sistema. Não foi possível inserir uma nova credencial',
  CANNOT_SELECT_SAME_TECHNOLOGY: 'Não é possível repetir a tecnologia do crachá',
  CREDENTIAL_EXPIRED: 'A credencial está expirada',
  OPERATION_ERROR: 'Não foi possível executar a operação',
  MUST_HAVE_A_MANDATORY_DOCUMENT: 'Pessoa deve possuir algum documento obrigatório',
  FINAL_STEP: 'Terminar',
  BARCODE: 'Barras',
  PROXIMITY: 'Proximidade',
  SMARTCARD: 'Smart Card',
  ROLE_EXPIRATION: 'Validade do papel',
  DOCUMENT_ALREADY_IN_USE: 'O documento não pode ser adicionado porque já existe',
  PERSON_ALREADY_HAS_THIS_ROLE: 'A pessoa já possui este papel',
  REGISTER_ENTRY_BUTTON: 'Registrar entrada',
  DOES_NOT_HAVE_FLOOR_PLAN: '{{physicalLocation}} não possui planta baixa',
  SELECT_AN_IMAGE_TO_BEGIN_THE_MAP: 'Selecione uma imagem para começar o mapa',
  IT_WILL_BE_USED_WITH_THE_11_ASPECT_RATIO: 'Ela será usada em aspecto 1:1',
  ERROR_WHILE_LOAD_IMAGE: 'Erro ao carregar imagem',
  ONLY_IMAGES_IN_JPEG_AND_PNG_FORMAT_ARE_ALLOWED: 'Apenas imagens no formato PNG e JPEG são aceitas',
  CONFIRMATION: 'Confirmação',
  YES: 'Sim',
  NO: 'Não',
  ALREADY_HAS_ACCESS_CREDENTIAL: 'Essa pessoa já possui uma credencial de mesmo formato em aberto.',
  TERMINATE_ACCESS_CREDENTIAL: 'Deseja finalizar a credencial?',
  ID_ALREADY_BEING_USED: 'Esse número de crachá já está sendo utilizado por {{personNameAndDocument}}',
  ROLE_WHITOUT_PHYSICAL_LOCATION_RANGE: 'Seu papel não possui abrangência para ver as informações desse local.',
  CLEAR_FORM: 'Limpar',
  TOUR_MOVEMENTS_INTRODUCTION: 'No menu Movimentação, é possível entregar uma nova credencial e registrar entradas e saídas.',
  TOUR_REGISTRY_ENTRY_INTRODUCTION: 'Agora, você vai fazer o Registro de entrada.',
  TOUR_REGISTRY_ENTRY_STEP_1: 'No passo 1, selecione um tipo de documento, informe seu valor e o nome da pessoa, clicando em Próximo em seguida.',
  TOUR_REGISTRY_ENTRY_STEP_2: 'No passo 2, você pode incluir os detalhes da pessoa, ou ir direto para o Próximo passo.',
  TOUR_REGISTRY_ENTRY_STEP_3: 'No passo 3, informe o papel e sua validade e opcionalmente, a pessoa visitada.',
  SKIP: 'Pular',
  MASTER_CONTROLLERS: 'Gerenciadores',
  MASTER_CONTROLLER: 'Gerenciador',
  MODULES: 'Módulos',
  MODULE: 'Módulo',
  CHILD_LOCATIONS: 'Locais filhos',
  CHILD_LOCATION: 'Local filho',
  INPUTS: 'Entradas',
  INPUT: 'Entrada',
  CAMERA: 'Câmera',
  NO_CHILD_LOCATIONS_AVALIABLE: 'Não existem locais filhos disponíveis',
  SELECT_A_CHILD_LOCATION: 'Selecione um local filho',
  REMOVE: 'Remover',
  SELECT: 'Selecionar',
  SELECT_A_CAMERA: 'Selecione uma câmera',
  NO_CAMERAS_AVALIABLE: 'Não existem câmeras disponíveis',
  SELECT_A_MASTER_CONTROLLER: 'Selecione um gerenciador',
  SELECT_A_MODULE: 'Selecione um módulo',
  SELECT_AN_INPUT: 'Selecione uma entrada',
  NO_MASTER_CONTROLLERS_AVALIABLE: 'Não existem gerenciadores disponíveis',
  NO_MODULES_AVALIABLE: 'Não existem módulos disponíveis',
  NO_INPUTS_AVALIABLE: 'Não existem entradas disponíveis',
  DELETE_FLOOR_PLAN: 'Excluir planta baixa',
  UPLOAD_NEW_IMAGE: 'Upload de nova imagem',
  INVERT_COLORS: 'Inverter cores',
  DOCUMENTATION: 'Documentação',
  EDIT_FLOOR_PLAN: 'Editar planta baixa',
  RETURN_TO_VISUALIZATION: 'Retornar para visualização',
  WANT_TO_DELETE_THE_FLOOR_PLAN: 'Deseja excluir a planta baixa?',
  ACCESS_CALL_DEVICE_TYPE: 'Tipo de dispositivo',
  ACCESS_CALL_DEVICE_NAME: 'Nome do dispositivo',
  ACCESS_CALL_ORIGIN_DEVICE_READER: 'Leitora de acesso',
  ACCESS_CALL_ORIGIN_SELFSERVICE_TERMINAL: 'Terminal de autoatendimento',
  ACCESS_CALL_GRANT_ACCESS: 'Permitir acesso',
  ACCESS_CALL_DENY_ACCESS: 'Negar acesso',
  ACCESS_CALL_LABEL: 'Chamada',
  ACCESS_CALL_OPEN_CALL: 'Abrir chamada',
  ACCESS_CALL_TIMEOUT_MSG_LT_MIN: 'Menos de 1 minuto atrás',
  ACCESS_CALL_TIMEOUT_MSG_EQ_MIN: '{{elapsedTimeout}} minuto atrás',
  ACCESS_CALL_TIMEOUT_MSG_GT_MIN: '{{elapsedTimeout}} minutos atrás',
  ACCESS_CALL_ORIGIN_DEVICE_INPUT: 'Botoeira',
  ACCESS_CALL_ACCESS_GRANTED: 'Acesso concedido',
  ACCESS_CALL_ACCESS_GRANTED_ERROR: 'Não foi possível conceder o acesso',
  ACCESS_CALL_ACCESS_DENIED: 'Acesso negado',
  ACCESS_CALL_ACCESS_DENIED_ERROR: 'Não foi possível negar o acesso',
  ACCESS_DENIED_PERSON_NOT_ASSOCIATED_WITH_VEHICLE: 'Acesso negado por pessoa não associada com veículo',
  ACCESS_CALL_OPENED_CALLS: 'Chamadas em aberto',
  ACCESS_CALL_DETAILS_SELECTOR: 'Detalhes',
  ACCESS_CALL_MESSAGES_SELECTOR: 'Mensagens',
  ACCESS_CALL_MESSAGE_SEND: 'Enviar',
  ACCESS_CALL_MESSAGE_NOT_SENT: 'Mensagem não enviada',
  ACCESS_CALL_MESSAGE_PLACEHOLDER: 'Digite aqui sua mensagem',
  ACCESS_CALL_MESSAGE_CHARS_LEFT: 'Restam {{remainingChars}} caracteres',
  NO_CAMERAS_RELATED: 'Nenhuma câmera associada.',
  OBTAINING_INFORMATION_FROM_THE_SERVER: 'Obtendo informações do servidor',
  ROLE_WITHOUT_PHYSICAL_LOCATION_RANGE: 'Seu papel não possui abrangência para ver as informações desse local.',
  NO_CAMERAS: 'Nenhuma câmera',
  LIVE: 'Ao vivo',
  TOUR_INITIAL: 'Já que se interessou pela Portaria virtual, que tal um tour para conhecer alguns recursos?',
  TOUR_CAMERA: 'Primeiro, conheça o mosaico das câmeras das entradas e saídas. Você pode mudar a câmera que é exibida.',
  TOUR_ACTIONS: 'Para que a pessoa possa entrar, é necessário fazer a ação de Abrir porta',
  TOUR_OPEN_ENTRANCES: 'Uma vez que a pessoa deu entrada na portaria, é possível vê-la nas Entradas em aberto.',
  HIERARCHY: 'Hierarquia',
  INACTIVE: 'Inativo',
  ACTIVE: 'Ativo',
  STATUS: 'Status',
  CURRENT_STATE: 'Estado atual',
  CARD_IS_NOT_PRESENT: 'O cartão não está presente na leitora.',
  READER_IS_NOT_CONNECTED: 'Não foi possível abrir um canal de comunicação com a leitora. Verifique se a leitora está conectada no computador.',
  TOUR_CHECKOUT_INTRODUCTION: 'Na hora de a pessoa ir embora, clique em Registro de saída',
  TOUR_CHECKOUT_STEP_1: 'Informe parte do nome ou documento da pessoa, localize-a, clique sobre seu cadastro e depois em Próximo.',
  TOUR_CHECKOUT_STEP_2: 'Confirme os dados e então use o botão Registrar saída.',
  TOUR_END: 'Agora você já sabe como registrar uma entrada, fazer uma ação e controlar saídas pela Portaria virtual. Continue usando o produto para conhecer mais recursos e confira a <a href="{{docUrl}}" target="_blank"><i class="fa fa-external-link"></i>documentação</a> para informações sobre estas e outras funcionalidades.',
  USE_PRODUCT: 'Usar produto',
  ON: 'Ligado',
  OFF: 'Desligado',
  CRITICAL: 'Críticos',
  HIGH: 'Altos',
  MEDIUM: 'Médios',
  LOW: 'Baixos',
  PLANNED: 'Planejados',
  OPEN_OCCURRENCES: 'Ocorrências em aberto',
  DEVICES_STATUS: 'Status dos dispositivos',
  ALARMED_INPUT_DEVICES: 'Entradas alarmadas',
  ALARMED_INPUT: 'Entrada alarmada',
  TOTAL_PEOPLE_PRESENT: 'Total de pessoas presentes',
  GRANTED: 'Permitidos',
  DENIED: 'Negados',
  REJECTED: 'Rejeitados',
  ACCESSES: 'Acessos da última hora',
  HOME: 'Início',
  YOU_ARE_OFFLINE_ATTEMPTING_TO_RECONNECT: 'Você está offline. Tentando reconexão',
  AN_UNEXPECTED_ERROR_OCCURRED_ON_THE_SERVER_ATTEMPTING_TO_RECONNECT: 'Ocorreu um erro inesperado no servidor. Tentando reconexão',
  ACCESS_CALL_LOG: 'Log de chamadas de acesso',
  ASSOCIATED_LOBBY: 'Portaria associada',
  NO_CALLS_REGISTERED: 'Nenhuma chamada registrada',
  FILTER: 'Filtrar',
  OPERATOR: 'Operador',
  CALL_STATUS: 'Status da chamada',
  ISSUE_ACCESS_CREDENTIAL: 'Entregar credencial',
  FINISHED_TIMED_OUT: 'Encerrada por timeout',
  FINISHED_ACCESS_ALLOWED: 'Encerrada com acesso permitido',
  FINISHED_ACCESS_DENIED: 'Encerrada com acesso negado',
  INITIAL_DATE: 'Data inicial',
  FINAL_DATE: 'Data final',
  CALL_DATE: 'Data da chamada',
  NO_MOVEMENT: 'Não houve movimentação',
  PREVIOUS: 'Anterior',
  NEXT: 'Próximo',
  NO_DATA_ON_NEXT_PAGE: 'Não há mais registros na proxima página',
  BLOCKED_PERSON_FOR_MORE_INFORMATION_ACCESS_THE_PERSON_REGISTRATION: 'Essa pessoa está bloqueada. Para mais informações acesse o cadastro da pessoa',
  ACCESS_DENIED_CUSTOM_VALIDATION: 'Por validação customizada',
  ACCESS_DENIED_BLOCK_PROVISORY_ON_EXIT: 'Por bloqueio de provisório na saída',
  ACCESS_DENIED_CONTROLS_IN_BETWEEN_WORKDAYS: 'Acesso negado por interjornada',
  TOUR_MONITORING_INTRODUCTION: 'O Security Hub é uma solução completa para monitorar seus locais físicos.',
  TOUR_MONITORING_STEP_1: 'No Analytics, monitore várias informações em tempo real, como o status das ocorrências através do painel de Ocorrências em Aberto',
  TOUR_MONITORING_STEP_2: 'Veja também o status dos dispositivos e entradas alarmadas...',
  TOUR_MONITORING_STEP_3: '...E confira o fluxo de Acessos das pessoas aos locais.',
  TOUR_MONITORING_STEP_4: 'Você pode acessar os diferentes Locais Físicos clicando sobre cada um deles.',
  TOUR_MONITORING_STEP_5: 'Monitore o status da Operação do local físico selecionado.',
  TOUR_MONITORING_STEP_6: 'Verifique os Últimos Acessos através deste painel.',
  TOUR_MONITORING_STEP_7: 'Saiba quais são as ocorrências relacionados ao local físico selecionado no momento.',
  TOUR_MONITORING_STEP_8: 'Monitore os Eventos de todos os dispositivos do local...',
  TOUR_MONITORING_STEP_9: '...E também todos os Dispositivos do local físico.',
  TOUR_MONITORING_STEP_10: 'Você pode acessar outros locais físicos, clicando em um dos Locais Filhos',
  TOUR_MONITORING_STEP_11: 'Crie uma Planta Baixa, como um mapa que facilita o monitoramento',
  TOUR_MONITORING_STEP_12: 'Você pode gerenciar sua planta baixa, através do botão Editar',
  TOUR_MONITORING_STEP_13: 'Adicione dispositivos e locais filhos clicando nos ícones habilitados e arrastando-os até a parte desejada da planta baixa.',
  TOUR_MONITORING_STEP_14: 'Você também pode excluir a planta baixa, fazer upload de uma nova imagem ou inverter as cores da imagem atual.',
  TOUR_MONITORING_STEP_15: 'Para acessar os detalhes de um dispositivo ou local filho, clique sobre seu ícone na planta baixa.',
  TOUR_MONITORING_STEP_16: 'Para voltar à tela de Operações, clique no botão Fechar.',
  TOUR_MONITORING_STEP_17: 'Monitore todas as Câmeras do local físico em um único lugar.',
  TOUR_MONITORING_STEP_18: 'Saiba quem são todas as Pessoas presentes no local.',
  TOUR_MONITORING_END: 'Você concluiu o tour da solução de Monitoramento de Ocorrências, agora pode continuar a usar o produto normalmente.',
  USERNAME: 'Usuário',
  WEBSOCKET_DISCONNECTED: 'Perdemos a comunicação com o servidor, estamos nos reconectando...',
  EVENTS_MONITORING: 'Monitoramento de eventos',
  ACCESS_EVENTS: 'Monitoramento de marcação de ponto',
  NO_CLOCKIN_REGISTERED: 'Nenhuma marcação de ponto registrada',
  PIS: 'PIS',
  CPF: 'CPF',
  NSR: 'NSR',
  CAPACITY: 'Capacidade',
  PRESENT: 'Presentes',
  ACCESS_DENIED_WITHOUT_MASK: 'Por falta de máscara',
  ACCESS_DENIED_ABNORMAL_TEMPERATURE: 'Por temperatura anormal',
  DEVICE_MONITORING: 'Monitoramento de dispositivos',
  ITEMS_PER_PAGE: 'Itens por página',
  NEXT_PAGE: 'Próxima página',
  PREVIOUS_PAGE: 'Página anterior',
  OF: 'de',
  SOUND_ALERT: 'Alerta sonoro',
  FINALIZE: 'Finalizar',
  NORMALIZE_THIS_DEVICE_MESSAGE: 'Deseja realmente normalizar o status deste dispositivo?',
  NORMALIZE_THIS_DEVICE_TITLE: 'Normalizar',
  NORMALIZE: 'Normalizar',
  ACCESS_DENIED_ROLE_RANGE: 'Negado por faixa horária',
  VEHICLE_ACCESS_MONITORING: 'Monitoramento de acesso de veículos',
  PLATE: 'Placa',
  YOU_NOT_HAVE_LICENSE_TO_ACCESS_THIS_CONTENT: 'Você não possui licenças para acessar esse conteúdo'
};

export const translateEn = {
  DOC_URL_ACTIONS: 'https://documentacao.senior.com.br/seniorxplatform/manual-do-usuario/ronda/#security-hub/monitoramento.htm',
  DOC_URL_MONITORING: 'https://documentacao.senior.com.br/seniorxplatform/manual-do-usuario/ronda/#security-hub/monitoramento.htm',
  DOC_URL_VIRTUAL_LOBBY: 'https://documentacao.senior.com.br/seniorxplatform/manual-do-usuario/ronda/#security-hub/monitoramento.htm',
  PHYSICAL_LOCATIONS: 'Physical locations',
  OPERATION: 'Operation',
  FLOOR_PLAN: 'Floor plan',
  CAMERAS: 'Cameras',
  INFORMATION_RELOAD_IN: 'The information will be updated in',
  RELOAD_NOW: 'Reload now',
  PEOPLE_PRESENT: 'People present',
  IRREGULAR_PRESENCES: 'Irregular presences',
  IRREGULAR_PRESENCE_BY_WORK_SCHEDULE: 'People with an irregular presence (reason: schedule)',
  NO_IRREGULAR_PRESENCE_AT_THE_LOCATION: 'No irregular presences in this location',
  WEB_MONITORING: 'Web monitoring',
  DATE_OF_ACCESS: 'Access date',
  PHONE_NUMBER: 'Phone number',
  LOCATION: 'Location',
  DOES_NOT_HAVE_ANY_CHILD_PHYSICAL_LOCATIONS: '{{physicalLocation}} does not have any child physical locations.',
  CHILD_PHYSICAL_LOCATIONS: 'Child physical locations',
  OCCURRENCES: 'Occurrences',
  PRIORITY_PLANNED: 'Planned',
  PRIORITY_LOW: 'Low priority',
  PRIORITY_MEDIUM: 'Medium priority',
  PRIORITY_HIGH: 'High priority',
  PRIORITY_CRITICAL: 'Critical',
  PENDING: 'Pending',
  IN_PROGRESS: 'In progress',
  STOPPED: 'Paused',
  DONE: 'Done',
  NO_PERSON_PRESENT_AT_THE_LOCATION: 'No people present at this physical location',
  LAST_ACCESSES: 'Latest accesses',
  DOES_NOT_HAVE_ANY_ACCESS: '{{physicalLocation}} has no accesses registered.',
  UNKNOWN_ACCESS_VALID: 'Access granted',
  UNKNOWN_ACCESS_DENIED: 'Access denied',
  ENTRANCE_ACCESS_VALID: 'Entry granted',
  ENTRANCE_ACCESS_DENIED: 'Entry denied',
  EXIT_ACCESS_VALID: 'Exit granted',
  EXIT_ACCESS_DENIED: 'Exit denied',
  DEVICES: 'Devices',
  DOES_NOT_HAVE_ANY_DEVICE: '{{physicalLocation}} has no devices.',
  DOES_NOT_HAVE_ANY_OCCURRENCES: '{{physicalLocation}} has no occurrences registered.',
  EVENTOS: 'Events',
  DEVICE_STARTED: 'Device started',
  DEVICE_ONLINE: 'Online device',
  DEVICE_OFFLINE: 'Offline device',
  OFFLINE_MODULE: 'Offline module',
  ISSUANCE_OF_TIME_AND_ATTENDANCE_REPORT: 'Issuance of time and attendance report',
  STARTED_USING_USB_FISCAL_PORT: 'Using USB fiscal port',
  PAPER_REEL_REPLACED: 'Paper reel replaced',
  LACK_OF_PAPER: 'No paper',
  NO_PAPER: 'No paper',
  LOW_MEMORY: 'Low memory',
  NO_MEMORY: 'Memory',
  BATTERY_REPLACED: 'Battery replaced',
  STARTED_USING_BATTERY: 'On battery',
  STARTED_USING_ENERGY: 'On energy',
  DEVICE_LOCKED: 'Device blocked',
  DEVICE_UNLOCKED: 'Device unlocked',
  TAMPER: 'Tamper',
  DOOR_HELD_OPEN: 'Door held open',
  DOOR_FORCED_OPEN: 'Door forced open',
  MEMORY: 'Memory',
  BATTERY: 'Battery',
  PAPER: 'Paper',
  ALARMED: 'Alarmed',
  DEFAULT_STATE: 'Default state',
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  DOES_NOT_HAVE_ANY_EVENTS: '{{physicalLocation}} has no events registered.',
  UNLOCKED: 'Unlocked',
  LOCKED: 'Locked',
  POWER: 'Rede',
  HAS_PAPER: 'Has paper',
  AVALIABLE_MEMORY: 'Available',
  EXIT: 'Exit',
  ENTRANCE: 'Entry',
  UNKNOWN: 'Unknown',
  ACCESS_VALID: 'Access granted',
  ACCESS_DENIED_PERMISSION: 'Location not allowed',
  ACCESS_DENIED_PERSON_NOT_ASSOCIATED_WITH_VEHICLE: 'Access denied due to the person not being associated with the vehicle',
  ACCESS_DENIED_SITUATION: 'Denied due situation',
  ACCESS_DENIED_CARD_VALIDITY: 'Credential validity',
  ACCESS_DENIED_LEVEL_CONTROLER: 'Level control',
  ACCESS_DENIED_CREDIT_ACCESS: 'Due to lack of access credits',
  ACCESS_DENIED_PERSON_RANGE: 'Due to the person\'s time slot',
  ACCESS_DENIED_LOCAL_RANGE: 'Due to the location time slot',
  ACCESS_DENIED_CARD_NOT_FOUND: 'ID card not found',
  ACCESS_VALID_ACCOMPANY: 'Accompanied by authorizer',
  ACCESS_DENIED_ACCOMPANY: 'Accompanied by authorized',
  ACCESS_DENIED_INVALID_AUTHORIZER: 'Incorrect authorizer',
  ACCESS_DENIED_WAITING_FOR_NEXT_VALIDATION: 'Awaiting next validation',
  ACCESS_DENIED_ANTI_PASSBACK: 'Blocked by anti-passback',
  ACCESS_DENIED_CREDIT_RANGE: 'Out of the access credits range',
  ACCESS_DENIED_STOCKING_CONTROL: 'Maximum occupancy reached',
  ACCESS_DENIED_NOT_PARKING_SPACE_TYPE: 'Vacancy type not available',
  ACCESS_DENIED_NOT_PARKING_SPACE: 'No vacancy',
  ACCESS_COERCION: 'Access under duress',
  ACCESS_DENIED_BIOMETRY: 'Biometrics',
  ACCESS_DENIED_CARD_FORMAT_ERROR: 'Invalid ID card format',
  ACCESS_DENIED_FACILITY_CODE: 'Facility code',
  ACCESS_DENIED_PASSWORD: 'Password',
  ACCESS_DENIED_SECOND_CARD_NOT_PRESENTED: 'Second ID card not presented',
  ACCESS_DESISTENCE: 'Access discontinuation',
  ACCESS_VALID_AUTHORIZER: 'By the authorizer',
  ACCESS_VALID_FACILITY_CODE: 'Due to the facility code',
  ACCESS_VALID_FRAUD: 'Turnstile bypass',
  ACCESS_VALID_OUT_REPOSE: 'Out of standby',
  ACCESS_DENIED: 'Access denied',
  ACCESS_DENIED_CREDENTIAL_NOT_FOUND: 'ID Card not found',
  ACCESS_DENIED_CREDENTIAL_VALIDITY: 'Due to the credential validity',
  MONITOR: 'Monitor',
  PERSON: 'Person',
  DIRECTION: 'Direction',
  PHYSICAL_LOCATION: 'Physical location',
  DEVICE: 'Device',
  ACCESS_TYPE: 'Type of access',
  ACCESS_MONITORING: 'Access monitoring',
  ACCESS_DATE_TIME: 'Access date/time',
  NAME: 'Name',
  DEVICE_STATUS: 'Device status',
  ADDRESS: 'Address',
  PAPER_STATUS: 'Paper status',
  MEMORY_STATUS: 'Memory status',
  POWER_SUPPLY: 'Power supply',
  SITUATION: 'Situation',
  NO_REGISTERED_DEVICES: 'No devices registered',
  DEVICE_SEARCH: 'Device search',
  NEW_CREDENTIAL: 'New credential',
  REGISTER_ENTRANCE: 'Entry register',
  REGISTER_CHECKOUT: 'Exit register',
  MOVEMENTS: 'Movement',
  DETAILS: 'Details',
  OPEN_ENTRANCES: 'Open entries',
  ASSOCIATED_PEOPLE: 'Associated people',
  INFORMATION: 'Information',
  ACTIONS: 'Actions',
  IDENTIFICATION: 'Identification',
  PERSON_DETAILS: 'Person details',
  REGISTER_ENTRY: 'Entry register',
  SELECT_A_DOCUMENT_TYPE: '< Select a type',
  NEXT_STEP: 'Next',
  TYPE_TO_SEARCH: 'Type to search',
  DOCUMENT: 'Identify document',
  EMAIL_ADDRESS: 'Email address',
  BACK: 'Back',
  ROLE: 'Role',
  EXPIRATION_DATE: 'Expiration date',
  VISITED_PERSON: 'Visited person',
  SAVE: 'Save',
  SEARCH_VISITED_PERSON: 'Search for visited person',
  DOCUMENT_TYPE: 'Type of document',
  MANDATORY_FIELD: 'Mandatory field',
  SAVED_SUCCESSFULLY: 'Saved successfully',
  CHECKOUT: 'Exit register',
  SELECT_PERSON: 'Select a person',
  REGISTER_LEAVE: 'Register exit',
  CANCEL: 'Cancel',
  NAME_OR_DOCUMENT: 'Name or identity document',
  THE_ADDRESS_MIGHT_BE_INCORRECT_NOT_AVAILABLE_ANYMORE_THE_CONTENT_MIGHT_HAVE_BEEN_REMOVED: 'The address might be incorrect, not available anymore or the content might have been removed.',
  PAGE_NOT_FOUND: 'Page not found',
  PAGE_ERROR_ACCESS_DENIED: 'Access denied',
  YOU_NOT_HAVE_PERMISSIONS_TO_ACCESS_THIS_CONTENT: 'You do not have permission to access this content.',
  OOPS: 'Oops!',
  AN_UNEXPECTED_ERROR_OCCURRED_ON_THE_SERVER: 'An unexpected error occurred on the server',
  THIS_MIGHT_HAVE_OCCURRED_DUE_TO_MAINTENANCE_ON_OUR_SERVERS: 'This might have occurred due to a maintenance on our servers',
  NORMAL: 'Normal',
  SELECT_CAMERA: 'Select the camera',
  CAMERA_NOT_FOUND: 'Camera not found',
  ERROR_EXECUTING_LIVE_STREAMING: 'Could not obtain image from the server',
  NO_CCTV_CAMERAS: 'Cannot search for the image record of cameras that have no server',
  HIDE_CAMERA: 'Hide camera',
  UNAVAIBLE_SERVICE: 'Service unavailable',
  PROXY_SERVER_NOT_FOUND: 'Proxy server not found',
  NO_CAMERAS_ASSOCIATED_TO_LOCATION: 'No cameras associated with this location',
  SELECTED_FILTERS: 'Filters selected',
  ALL: 'All',
  GROUP: 'Group',
  FILTERS: 'Filters',
  NO_ACCESS_REGISTERED: 'No access registered',
  CLEAR: 'Clear',
  SEARCH: 'Search',
  SELECT_FILTERS: 'Select filters',
  CLOSE_NOTIFICATIONS: 'Close notifications',
  VIEW_MORE: 'View more',
  NO_NOTIFICATIONS: 'No notifications',
  NO_AVAILABLE_ACTIVE_LOBBY: 'No available active lobby was found for your user',
  CONTACT_SYSTEM_ADMINISTRATOR: 'If you have any questions, please contact your system administrator',
  WEB_MONITOR: 'Web Monitor',
  VIRTUAL_LOBBY: 'Virtual Lobby',
  HELP: 'Help',
  NOTIFICATIONS: 'Notifications',
  LOGOFF: 'Log off',
  NO_INFORMATION_LOBBY: 'No additional information available for this lobby',
  NO_ACTIONS_REGISTERED: 'No actions registered',
  FIND_MORE_HERE: 'Find out more here',
  THIS_VIRTUAL_LOBBY_HAS_NO_OPEN_ENTRIES: 'This lobby has no open entries',
  REGISTERED_LEAVE_SUCCESSFUL_FOR_PERSON: 'Exit register executed successfully for {person}',
  THERE_ARE_NO_MEMBERS_ASSOCIATED_WITH_THIS_ENTRY: 'No people associated with this lobby',
  OPEN_ENTRIES: 'Open entries',
  ERROR_VALIDATING_USER: 'Could not obtain image from the server',
  ADD_CAMERA_TO_CONTAINER: 'Add a camera',
  ACTIVATION_EXECUTED_SUCCESSFULLY: 'Activation executed successfully',
  ROLE_FOR_PERSON_ROLE_OVERLAP: 'This person already has this role',
  SAVE_FAILED: 'Save failed',
  CREDENTIAL_NUMBER: 'Access credential number',
  IDENTITY_DOCUMENT: 'Identity document',
  SELECT_CREDENTIAL_TYPE: '< Select a type',
  ISSUE_CREDENTIAL: 'Issue credential',
  ISSUE_CREDENTIAL_BUTTON: 'Issue',
  PERSON_ROLE_NOT_ACTIVE_PERSON: 'Cannot grant access to a blocked person, for more information contact your system administrator.',
  EXPIRATION_DATE_CANNOT_BE_LATER_THAN_CURRENT_DATE: 'The expiration date cannot be later than the current date',
  CANNOT_GRANT_ACCESS_TO_BLOCKED_PERSON_FOR_MORE_INFORMATION_CONTACT_YOUR_ADMINISTRATOR: 'Cannot grant access to a blocked person, for more information contact your system administrator.',
  CANNOT_GRANT_ACCESS_TO_BLOCKED_PERSON_FOR_MORE_INFORMATION_ACCESS_THE_PERSON_REGISTRATION: 'Cannot grant access to a blocked person, for more information access the person registration.',
  ACCESS: 'Access',
  CREDENTIAL_EMITTED_SUCCESSFUL_FOR_PERSON: 'Credential successfully issued for {person}',
  CREDENTIAL_NUMBER_ALREADY_IN_USE: 'This ID Card number is already being used by another person',
  VISITED_AND_VISITOR_SAME_PERSON: 'The visitor and the visited person cannot be the same person',
  CREDENTIAL_LIMIT_EXCEEDED: 'The active credential limit has been exceeded. Cannot add a new credential',
  CANNOT_SELECT_SAME_TECHNOLOGY: 'Cannot duplicate the ID Card technology',
  CREDENTIAL_EXPIRED: 'The credential is expired',
  OPERATION_ERROR: 'Could not execute the operation',
  MUST_HAVE_A_MANDATORY_DOCUMENT: 'The person must have a mandatory document',
  FINAL_STEP: 'Finish',
  BARCODE: 'Bar code',
  PROXIMITY: 'Proximity',
  SMARTCARD: 'Smart Card',
  ROLE_EXPIRATION: 'Role expiration date',
  DOCUMENT_ALREADY_IN_USE: 'Could not add the identity document as it already exists',
  PERSON_ALREADY_HAS_THIS_ROLE: 'This person already has this role',
  REGISTER_ENTRY_BUTTON: 'Register entry',
  DOES_NOT_HAVE_FLOOR_PLAN: '{{physicalLocation}} does not have a floor plan',
  SELECT_AN_IMAGE_TO_BEGIN_THE_MAP: 'Select an image to begin the map',
  IT_WILL_BE_USED_WITH_THE_11_ASPECT_RATIO: 'It will be used with the 1:1 aspect ratio',
  ERROR_WHILE_LOAD_IMAGE: 'Error while loading image',
  ONLY_IMAGES_IN_JPEG_AND_PNG_FORMAT_ARE_ALLOWED: 'Only images in PNG or JPEG format are allowed',
  CONFIRMATION: 'Confirmation',
  YES: 'Yes',
  NO: 'No',
  ALREADY_HAS_ACCESS_CREDENTIAL: 'This person already has an open access credential of this format.',
  TERMINATE_ACCESS_CREDENTIAL: 'Are you sure you want to terminate the access credential?',
  ID_ALREADY_BEING_USED: 'This ID card number is already being used by {{personNameAndDocument}}',
  ROLE_WHITOUT_PHYSICAL_LOCATION_RANGE: 'Your role does not have range to view information about this location.',
  CLEAR_FORM: 'Clear',
  TOUR_MOVEMENTS_INTRODUCTION: 'In the Movement menu, you can issue a new credential and register entries and exits.',
  TOUR_REGISTRY_ENTRY_INTRODUCTION: 'You will now register an entry.',
  TOUR_REGISTRY_ENTRY_STEP_1: 'In step 1, select a document type, enter its value and the person\'s name, and then click Next.',
  TOUR_REGISTRY_ENTRY_STEP_2: 'In step 2, you can add the person\'s details, or go straight to the Next step.',
  TOUR_REGISTRY_ENTRY_STEP_3: 'In step 3, define the role and its validity and optionally, the visited person.',
  SKIP: 'Skip',
  MASTER_CONTROLLERS: 'Master controllers',
  MASTER_CONTROLLER: 'Master controller',
  MODULES: 'Modules',
  MODULE: 'Module',
  CHILD_LOCATIONS: 'Child locations',
  CHILD_LOCATION: 'Child location',
  INPUTS: 'Input devices',
  INPUT: 'Input device',
  CAMERA: 'Camera',
  ROLE_WITHOUT_PHYSICAL_LOCATION_RANGE: 'Your role does not have range to view information about this location.',
  NO_CHILD_LOCATIONS_AVALIABLE: 'No child locations available',
  SELECT_A_CHILD_LOCATION: 'Select a child location',
  REMOVE: 'Remove',
  SELECT: 'Select',
  SELECT_A_CAMERA: 'Select a camera',
  NO_CAMERAS_AVALIABLE: 'No cameras available',
  SELECT_A_MASTER_CONTROLLER: 'Select a master controller',
  SELECT_A_MODULE: 'Select a module',
  SELECT_AN_INPUT: 'Select an input device',
  NO_MASTER_CONTROLLERS_AVALIABLE: 'No master controllers available',
  NO_MODULES_AVALIABLE: 'No modules available',
  NO_INPUTS_AVALIABLE: 'No input devices available',
  DELETE_FLOOR_PLAN: 'Delete floor plan',
  UPLOAD_NEW_IMAGE: 'Upload new image',
  INVERT_COLORS: 'Invert colors',
  DOCUMENTATION: 'Documentation',
  EDIT_FLOOR_PLAN: 'Edit floor plan',
  RETURN_TO_VISUALIZATION: 'Return to visualization',
  WANT_TO_DELETE_THE_FLOOR_PLAN: 'Do you want to delete the floor plan?',
  ACCESS_CALL_DEVICE_TYPE: 'Device type',
  ACCESS_CALL_DEVICE_NAME: 'Device name',
  ACCESS_CALL_ORIGIN_DEVICE_READER: 'Access reader',
  ACCESS_CALL_ORIGIN_SELFSERVICE_TERMINAL: 'Self-service terminal',
  ACCESS_CALL_GRANT_ACCESS: 'Grant access',
  ACCESS_CALL_DENY_ACCESS: 'Deny access',
  ACCESS_CALL_LABEL: 'Call',
  ACCESS_CALL_OPEN_CALL: 'Open call',
  ACCESS_CALL_TIMEOUT_MSG_LT_MIN: 'Less than 1 minute',
  ACCESS_CALL_TIMEOUT_MSG_EQ_MIN: '{{elapsedTimeout}} minute ago',
  ACCESS_CALL_TIMEOUT_MSG_GT_MIN: '{{elapsedTimeout}} minutes ago',
  ACCESS_CALL_ORIGIN_DEVICE_INPUT: 'Switch',
  ACCESS_CALL_ACCESS_GRANTED: 'Access granted',
  ACCESS_CALL_ACCESS_GRANTED_ERROR: 'Could not grant access',
  ACCESS_CALL_ACCESS_DENIED: 'Access denied',
  ACCESS_CALL_ACCESS_DENIED_ERROR: 'Could not deny access',
  ACCESS_CALL_OPENED_CALLS: 'Opened calls',
  ACCESS_CALL_DETAILS_SELECTOR: 'Details',
  ACCESS_CALL_MESSAGES_SELECTOR: 'Messages',
  ACCESS_CALL_MESSAGE_SEND: 'Send',
  ACCESS_CALL_MESSAGE_NOT_SENT: 'Message not sent',
  ACCESS_CALL_MESSAGE_PLACEHOLDER: 'Type your message here',
  ACCESS_CALL_MESSAGE_CHARS_LEFT: '{{remainingChars}} characteres left',
  NO_CAMERAS_RELATED: 'No cameras associated.',
  OBTAINING_INFORMATION_FROM_THE_SERVER: 'Obtaining information from the server',
  NO_CAMERAS: 'No cameras.',
  LIVE: 'Live',
  TOUR_INITIAL: 'Since you are interested in Virtual Lobby, how about a tour to know some of its features?',
  TOUR_CAMERA: 'First, get to know the mosaic of cameras in entrances and exits. You can change the camera that is displayed.',
  TOUR_ACTIONS: 'To allow the person to enter, the Open Door action must be executed',
  TOUR_OPEN_ENTRANCES: 'Once the person has entered through the lobby, you can see him or her in the Open entries.',
  HIERARCHY: 'Hierarchy',
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
  STATUS: 'Status',
  CURRENT_STATE: 'Current state',
  CARD_IS_NOT_PRESENT: 'Card not present in the reader.',
  READER_IS_NOT_CONNECTED: 'Could not open a communication channel with the reader. Please check whether the reader is connected to the computer.',
  TOUR_CHECKOUT_INTRODUCTION: 'When a person has to leave, click in Exit register',
  TOUR_CHECKOUT_STEP_1: 'Enter part of the person\'s name or document, find him or her, click in the register and then in Next.',
  TOUR_CHECKOUT_STEP_2: 'Confirm the data and then use the Register exit button.',
  TOUR_END: 'Now you know how to register an entry, execute an action and control exits with the Virtual lobby. Continue using the product to know more features and check the <a href="{{docUrl}}" target="_blank"><i class="fa fa-external-link"></i>documentation</a> for information about these and other functionalities.',
  USE_PRODUCT: 'Use product',
  ON: 'On',
  OFF: 'Off',
  CRITICAL: 'Critical',
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
  PLANNED: 'Planned',
  OPEN_OCCURRENCES: 'Open occurrences',
  DEVICES_STATUS: 'Devices status',
  ALARMED_INPUT_DEVICES: 'Alarmed input devices',
  ALARMED_INPUT: 'Alarmed input device',
  TOTAL_PEOPLE_PRESENT: 'Total people present',
  GRANTED: 'Granted',
  DENIED: 'Denied',
  REJECTED: 'Rejected',
  ACCESSES: 'Last hour accesses',
  HOME: 'Home',
  YOU_ARE_OFFLINE_ATTEMPTING_TO_RECONNECT: 'You are offline. Attempting to reconnect',
  AN_UNEXPECTED_ERROR_OCCURRED_ON_THE_SERVER_ATTEMPTING_TO_RECONNECT: 'An unexpected error occurred in the server. Attempting to reconnect',
  ACCESS_CALL_LOG: 'Access calls log',
  ASSOCIATED_LOBBY: 'Associated lobby',
  NO_CALLS_REGISTERED: 'No calls registered',
  FILTER: 'Filter',
  OPERATOR: 'Operator',
  CALL_STATUS: 'Call status',
  ISSUE_ACCESS_CREDENTIAL: 'Issue access credential',
  FINISHED_TIMED_OUT: 'Finished due to timeout',
  FINISHED_ACCESS_ALLOWED: 'Finished with access granted',
  FINISHED_ACCESS_DENIED: 'Finished with access denied',
  INITIAL_DATE: 'Start date',
  FINAL_DATE: 'End date',
  CALL_DATE: 'Call date',
  NO_MOVEMENT: 'No movement',
  PREVIOUS: 'Back',
  NEXT: 'Next',
  NO_DATA_ON_NEXT_PAGE: 'No data on next page',
  BLOCKED_PERSON_FOR_MORE_INFORMATION_ACCESS_THE_PERSON_REGISTRATION: 'This person is blocked. For more information, access the person\'s register',
  ACCESS_DENIED_CUSTOM_VALIDATION: 'By a custom validation',
  ACCESS_DENIED_BLOCK_PROVISORY_ON_EXIT: 'By a temporary ID Card blocking on exit',
  ACCESS_DENIED_CONTROLS_IN_BETWEEN_WORKDAYS: 'Access denied due to the in-between workdays control',
  TOUR_MONITORING_INTRODUCTION: 'The Security Hub is a complete solution to monitor your physical locations.',
  TOUR_MONITORING_STEP_1: 'In the Analytics, monitor many information in real time, such as the status of the occurrences with the Open Occurrences dashboard',
  TOUR_MONITORING_STEP_2: 'Also, verify the status of the triggered input devices...',
  TOUR_MONITORING_STEP_3: '...And verify the Access flow of the people in the location.',
  TOUR_MONITORING_STEP_4: 'You may access the different Physical Locations by clicking on each one of them.',
  TOUR_MONITORING_STEP_5: 'Monitor the Operation status of the selected physical location.',
  TOUR_MONITORING_STEP_6: 'Verify the Latest Accesses in this dashboard.',
  TOUR_MONITORING_STEP_7: 'Get to know the Occurrences that are related to the currently selected physical location.',
  TOUR_MONITORING_STEP_8: 'Monitor the Events of all devices of the location...',
  TOUR_MONITORING_STEP_9: '...And also all devices of the physical locations.',
  TOUR_MONITORING_STEP_10: 'You may access other physical locations by clicking on one of the Child Locations',
  TOUR_MONITORING_STEP_11: 'Create a Floor Plan, like a map which makes monitoring easier',
  TOUR_MONITORING_STEP_12: 'You may manage your floor plan with the Edit button',
  TOUR_MONITORING_STEP_13: 'Add devices and locations by clicking in the enabled icons and dragging them to the desired part of the floor plan.',
  TOUR_MONITORING_STEP_14: 'You may also delete the floor plan, upload a new image or invert the colors of the current image.',
  TOUR_MONITORING_STEP_15: 'To access the details of a device or child location, click on its icon in the floor plan.',
  TOUR_MONITORING_STEP_16: 'To return to the Operations screen, click in the Close button.',
  TOUR_MONITORING_STEP_17: 'Monitor all Cameras of the physical location in one place.',
  TOUR_MONITORING_STEP_18: 'Know who are all the People present in a location.',
  TOUR_MONITORING_END: 'You finished the Occurrence Monitoring tour, now you can continue using the product as usual.',
  USERNAME: 'User',
  WEBSOCKET_DISCONNECTED: 'We lost communication with the server, reconnecting...',
  EVENTS_MONITORING: 'Event monitoring',
  ACCESS_EVENTS: 'Clocking event monitoring',
  NO_CLOCKIN_REGISTERED: 'No clocking event registered',
  PIS: 'PIS',
  CPF: 'CPF',
  NSR: 'NSR',
  CAPACITY: 'Capacity',
  PRESENT: 'Present',
  ACCESS_DENIED_WITHOUT_MASK: 'Due to lack of mask',
  ACCESS_DENIED_ABNORMAL_TEMPERATURE: 'Due to abnormal temperature',
  DEVICE_MONITORING: 'Device monitoring',
  ITEMS_PER_PAGE: 'Items per page',
  NEXT_PAGE: 'Next page',
  PREVIOUS_PAGE: 'Previous page',
  OF: 'of',
  SOUND_ALERT: 'Sound alert',
  FINALIZE: 'Finalize',
  NORMALIZE_THIS_DEVICE_MESSAGE: 'Are you sure you want to normalize the status of this device?',
  NORMALIZE_THIS_DEVICE_TITLE: 'Normalize',
  NORMALIZE: 'Normalize',
  ACCESS_DENIED_ROLE_RANGE: 'Denied due to the timeslot',
  VEHICLE_ACCESS_MONITORING: 'Vehicle access monitoring',
  PLATE: 'License Plate',
  YOU_NOT_HAVE_LICENSE_TO_ACCESS_THIS_CONTENT: 'You do not have license to access this content.'
};

import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AccessCall } from '@shared/models/access-call.model';
import { TopicChannel } from '@shared/enums/topic-channel.enum';
import { AccessCallService } from '@core/access-call/access-call.service';
import { AccessCallStatus } from '@shared/models/access-call-status.model';
import { FullscreenService } from '@core/fullscreen/fullscreen.service';
import { WebSocketService } from '@core/web-socket/web-socket.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  public isLoadingApp = new Subject<boolean>();
  private ngUnsubscribe = new Subject();
  public isWebSocketDisconected = false;

  constructor(
    public accessCallService: AccessCallService,
    private fullscreenService: FullscreenService,
    private router: Router,
    private webSocketService: WebSocketService
  ) { }

  ngOnDestroy() {
    this.isLoadingApp.unsubscribe();
    if (this.ngUnsubscribe) {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
    this.webSocketService.onClose(TopicChannel.ACCESS_CALL, 'access-call');
  }

  ngOnInit() {
    this.webSocketService.connect();
    WebSocketService.isDisconected
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(status => this.isWebSocketDisconected = status);
    this.isLoadingApp.next(false);
    this.router.events
      .pipe(filter((event: any) => (event instanceof NavigationEnd || event instanceof NavigationStart)))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((navigationEvent) => {
        if (this.isRoutingParent(navigationEvent.url)) {
          this.isLoadingApp.next(navigationEvent instanceof NavigationStart);
        }
      });

    this.accessCallService
      .onAccessCalls()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((accessCalls: AccessCall[]) => {
        this.accessCallService.showAccessCallBanner(accessCalls[0]);
      });
  }

  private isRoutingParent(url) {
    const routingParent = ['/virtualLobby', '/monitoring', '/accessMonitoring', '/accessCallLog', '/deviceSearch'];
    return routingParent.some(ele => ele === url);
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUpEvent(event: KeyboardEvent) {
    return event.code !== 'F11';
  }

  @HostListener('document:webkitfullscreenchange', ['$event'])
  webkitfullscreenchange(event: any) {
    this.fullscreenChange(event);
  }

  @HostListener('document:fullscreenchange', ['$event'])
  fullscreenchange(event: any) {
    this.fullscreenChange(event);
  }

  @HostListener('document:mozfullscreenchange', ['$event'])
  mozfullscreenchange(event: any) {
    this.fullscreenChange(event);
  }

  fullscreenChange(event: any) {
    if (
      event.currentTarget.webkitIsFullScreen === false ||
      event.currentTarget.isFullScreen === false ||
      event.currentTarget.mozIsFullScreen === false ||
      event.currentTarget.msIsFullScreen === false
    ) {
      this.fullscreenService.exitFullscreenKeypress.emit(false);
    }
  }

  public onAccessCallAnswer(call) {
    this.accessCallService
      .changeStatus(call.id, AccessCallStatus.IN_PROGRESS)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.accessCallService.showAccessCallDetails(call);
      });
  }
}
